@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Font (Open Sans, Playfair Display) */
/* font-family: 'Open Sans', sans-serif; */
/* font-family: 'Playfair Display', serif; */

/* Define Custom Fonts */
@font-face {
  font-family: 'circular-std-black';
  src: url(/static/media/CircularStd-Black.b5fa1b55.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'circular-std-black-italic';
  src: url(/static/media/CircularStd-BlackItalic.2153a12d.woff) format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'circular-std-bold';
  src: url(/static/media/CircularStd-Bold.6f4fe3ca.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'circular-std-bold-italic';
  src: url(/static/media/CircularStd-BoldItalic.8d5dbe81.woff) format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'circular-std-medium';
  src: url(/static/media/CircularStd-Medium.e47ea4c0.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'circular-std-medium-italic';
  src: url(/static/media/CircularStd-MediumItalic.eddb7021.woff) format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'circular-std-book';
  src: url(/static/media/CircularStd-Book.c47d7e58.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'circular-std-book-italic';
  src: url(/static/media/CircularStd-BookItalic.cc06409e.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}



/* STYLES */

.body {
  /* TAG: change page margins here */
  /* width: 784px; */
  /* width: 100%; */
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.header {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 35px;
  /* font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 700; */
  font-family: circular-std-medium, Arial, sans-serif;
}

.header .navigation {
  width: 100%;
  display: flex;
}

.header .navigation div a {
  color: #BBBBBB;
  text-decoration: none;
  transition: color 0.2s;
}

.header .navigation div a:hover {
  color: black !important;
}

.header .navigation .active {
  color: black !important;
}

.header .navigation-sub {
  width: 276px;
  font-size: 22px;
  display: flex;
}

.header .navigation .natasha {
  font-size: 26px;
  width: calc(100% - 276px);
  line-height: 36px;
}

.header .navigation .navigation-sub .work,
.header .navigation .navigation-sub .about {
  padding-right: 40px;
}

.header .specialty {
  font-size: 22px;
  /* color: #75A6D6; */
  /* color: #2C83A8; */
  /* color: #6BA6FF; */
  color: #4088F4;
  line-height: 36px;
}

.header-project {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}



/* FOOTER */

.footer {
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.footer .footer-statement{
  /* font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 700; */
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 20px;
  line-height: 28px;
  margin: 0px auto 11px auto;
  width: 100%;
}

.footer .social-links {
  display: flex;
  justify-content: center;
  width: 100%;
}

.footer .social-links .social {
  background-color: #000000;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.footer .social-links .social-last {
  background-color: #000000;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  margin-right: 0px;
}

.footer .social-links .social .linkedin {
  max-width: 36px;
  max-height: 36px;
  padding: 11px 8px 8px 9px;

}

.footer .social-links .social-last .email {
  max-width: 36px;
  max-height: 36px;
  padding: 13px 8px 8px 8px;
}


.footer .footer-copyright {
  /* font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 600; */
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 15px;
  color: #B1B1B1;
  line-height: 36px;
  margin: 20px auto 0px auto;
  width: 100%;
}


/* MAIN PAGE */

/* Projects List */

.projects {
  width: 100%;
  /* font-family: 'Open Sans', 'Arial', sans-serif; */
  font-family: circular-std-medium, Arial, sans-serif;
  display: flex;
  justify-content: space-between;
}

.intro {
  /* font-family: 'Open Sans', 'Arial', sans-serif; */
  /* font-weight: 600; */
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 24px;
  color: grey;
  display: grid;
  grid-template-columns: 65% 35%;
  margin-bottom: 70px;
}

.intro .intro-text {
  margin-top: auto;
  margin-bottom: auto;
}

.intro .intro-name {
  font-family: 'Playfair Display', serif;
  font-weight: 800;
  color: black;
  /* font-family: circular-std-black, Arial, sans-serif;
  color: #75A6D6; */
  font-size: 75px;

}

.intro .intro-image {
  max-width: 300px;
  margin-left: 50px;
  /* box-shadow: 0px 0px 10px rgba(224, 224, 224, 0.5); */
  box-shadow: 2px 5px 5px 1px rgba(0,0,0,0.15) ;
}

/* Individual Project (Cards) */
.project {
  text-decoration: none;
}

.project a:hover {
  text-decoration: none;
}

.card {
  /* width: 20rem; */
  width: 1000px;
  margin-top: 85px;
  border: none;
  /* border-radius: 50%; */
  /* box-shadow: 0 4px 10px 0 rgba(57,86,113,0.24); */
}

/* .card:hover {
  box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253) !important;
} */

.card-title {
  font-family: circular-std-bold, Arial, sans-serif;
  font-size: 36px;
  /* font-weight: 700; */
  padding: 0px 0px 0px 25px;
}

.card-body {
  /* padding: 3rem 0 !important; */
  margin-top: auto;
  margin-bottom: auto;
}

.card-text {
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
  padding: 10px 0px 10px 25px;
}

.card-line {
  /* background: #000000; */
  height: 2px;
}

.card-line-top {
  margin-bottom: 80px;
}

/* .card-line-bottom {
  padding-top: 100px;
} */

.card-body .view-btn {
  margin-left: 20px;
  font-weight: 600;
  text-decoration: none;
  width: 190px;
  height: 50px;
  /* color: #000000;   set in STATE */
  /* font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 700; */
  font-family: circular-std-bold, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  background-color: white;
  border: 2px solid #000000;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.25s;
  margin-bottom: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .card-body .btn:hover {
  text-decoration: none;
  color: white;
  background-color: #6FCAD2;
  background-color: #000000;
  border: 2px solid #6FCAD2;
} */

.button-hover-0 {
  color: white !important;
  background-color: #A2BDBB !important;
  border: 2px solid #A2BDBB !important;
}

.button-hover-1 {
  color: white !important;
  background-color: #6FCAD2 !important;
  border: 2px solid #6FCAD2 !important;
}

.button-hover-2 {
  color: white !important;
  background-color: #DFBBA6 !important;
  border: 2px solid #DFBBA6 !important;
}

.button-hover-3 {
  color: white !important;
  background-color: #4088F4 !important;
  border: 2px solid #4088F4 !important;
}

.overflow {
  overflow: hidden;
}

.card-img-top {
  transform: scale(1);
  transition: transform 0.4s ease;
}

.card-img-top:hover {
  /* if "scale" is changed, chang in Project.js file too */
  transform: scale(1.05) !important;
}



/* ABOUT PAGE */
.about {
  width: 1000px;
}

.about-image {
  /* max-width: 1000px; */
  /* max-width: 500px; */
  /* width: 512px;
  height: 596px; */
  width: 426px;
  height: 496px;
  margin-left: 17px;
  margin-right: 50px;
  box-shadow: 0px 0px 10px rgba(224, 224, 224, 0.5);
  display: flex;
}

.about-content {
  /* margin-top: 50px; */
  /* font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 500; */
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
  line-height: 26px;
  margin-right: 0px;
}

.about-title {
  /* font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 700; */
  font-family: circular-std-bold, Arial, sans-serif;
  font-size: 20px;
}

.about .about-content a {
  text-decoration: underline;
  color: grey;
}

.about .about-content a:hover {
  color: black;
}



/* RESUME PAGE */

.resume-content {
  width: 100%;
  margin-top: 60px;
  /* display: flex; */
}

.resume-content a {
  text-decoration: none;
  width: 280px;
  height: 50px;
  /* color: #75A6D6; */
  /* color: #2C83A8; */
  color: #4088F4;
  /* font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 700; */
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  background-color: white;
  /* border: 2px solid #75A6D6; */
  /* border: 2px solid #2C83A8; */
  border: 2px solid #4088F4;
  cursor: pointer;
  border-radius: 38px;
  transition: all 0.4s;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resume-content a:hover {
  text-decoration: none;
  color: white;
  /* background-color: #75A6D6;
  border: 2px solid #75A6D6; */
  /* background-color: #6BA6FF;
  border: 2px solid #6BA6FF; */
  background-color: #4088F4;
  border: 2px solid #4088F4;
}

.resume-content img {
  /* max-width: 1000px; */
  /* display: flex; */
  width: 75%;
  display: block;
  margin: 0px auto;
}



/* PROJECTS */

/* Project Header */

.project-hero {
  /* margin-bottom: 50px; */
  width: 100%;
  /* position: absolute;
  left: 0;
  right: 0; */
  max-height: 80%;
}

.project-info-container {
  /* display: grid; */
  width: 100%;
  padding-top: 65px;
  padding-bottom: 80px;
  background-color: #F8F8F8;
}

.project-info {
  font-family: circular-std-book, Arial, sans-serif;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 7fr 1fr 4fr; 
  top: -106px;
  grid-column-gap: 30px;
  grid-row-gap: 10px;
}

.project-title {
  font-family: circular-std-bold, Arial, sans-serif;
  /* font-weight: 700; */
  font-size: 40px;
  color: black;
  line-height: 46px;
  margin-top: 12px;
  margin-bottom: 30px;
  grid-column: 1 / 4;
  grid-row: 1;
}

.project-description {
  grid-column: 1;
  grid-row: 2 / 6;
  line-height: 36px;
}

.project-info .title {
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 18px;
}

.project-info .text {
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
}

.date {
  grid-column: 2;
  grid-row: 2;
}

.duration {
  grid-column: 2;
  grid-row: 3;
}

.time {
  grid-column: 2;
  grid-row: 4;
}

.role {
  grid-column: 2;
  grid-row: 5;
}


.nav-scroll {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* z-index: 1; */
  z-index: 16;
  display: flex;
  width: 100%;
  height: 29px;
  background: darkgrey;
  list-style: none;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  justify-content: center;
  /* box-shadow: 0 6px 0 rgba(0,0,0,.7); */
  box-shadow: 0 6px 6px -6px rgba(0,0,0,.4);
}

.nav-scroll .scroll-item {
  margin: 0 35px;
  padding: 8px;
  color: white;
  font-family: circular-std-medium, Arial, sans-serif;
  /* font-weight: 500; */
  text-transform: uppercase;
  line-height: 12px;
}

.scroll-item > .active{
  border-bottom: 3px solid white;
  padding-bottom: 2px;
  color: white;
  /* font-weight: 800; */
  text-decoration: none;
  /* color: #242D34; */
}

.content-title {
  /* font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 700; */
  font-family: circular-std-bold, Arial, sans-serif;
  font-size: 40px;
  width: 1000px;
  /* max-width: 1000px; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  padding-top: 30px;
  padding-bottom: 15px;
  text-align: left;
  position: relative;
}

.content-title:after {
  content: "";
  display: block;
  width: 1000px;
  height: 4px;
  background: #EEEEEE;
  position: absolute;
  right: 0;
  top: 55%;
  z-index: -2;
}

.content-title > .number {
  color: "#000000";
  /* color: #12B7BB; */
  /* color: #6FCAD2; */
  background-color: white;
  padding-right: 25px;
}

.content-title .header {
  font-family: circular-std-bold, Arial, sans-serif;
  background-color: white;
  padding-right: 25px;
}

/* .content-title {
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 600;
  font-size: 40px;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
} */

.content-subtitle {
  /* font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 700; */
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 25px;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-bottom: 10px;
  /* color: #12B7BB; */
  color: #666666;
}

/* OLD CONTENT */
/* .content {
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
  line-height: 26px;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
} */

.content {
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
  line-height: 26px;
  width: 1000px;
  /* max-width: 1000px; */
  margin-left: auto;
  margin-right: auto;
}

/* equalicare survey results */
.content p a {
  text-decoration: none;
}

bold {
  font-family: circular-std-bold, Arial, sans-serif;
}

italic {
  font-family: circular-std-book-italic, Arial, sans-serif;
}

.other-title {
  font-family: circular-std-bold, Arial, sans-serif;
  font-size: 40px;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  padding-top: 30px;
  padding-bottom: 15px;
  text-align: left;
  position: relative;
}

.process {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  max-width: 1000px;
  grid-column-gap: 15px;
}

.process .process-header {
  font-family: circular-std-bold, Arial, sans-serif;
  font-size: 20px;
  line-height: 50px;
  vertical-align: middle;
}

.process .process-number {
  font-family: circular-std-bold, Arial, sans-serif;
  font-size: 40px;
  vertical-align: middle;
  line-height: 10px;
  padding-right: 10px;
  -webkit-text-stroke: 1.5px #000000;
  color: transparent;
  /* color: #12B7BB; */
  /* text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000; */
}

.process .process-text {
  padding-top: 15px;
  line-height: 30px;
  /* font-size: 16px; */
}




/* Project Bottom */

.thanks {
  width: 100%;
  background-color: black;
  padding-top: 48px;
  padding-bottom: 48px;
  /* margin-top: 40px; */
}

.thanks-text {
  /* font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 700; */
  font-family: circular-std-black, Arial, sans-serif;
  font-size: 22px;
  line-height: 26px;
  color: white;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.thanks-subtext {
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
  line-height: 36px;
  color: white;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.bottom-navigation {
  margin-top: 60px;
  width: 691px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-around;
}

.link {
  font-family: circular-std-medium, Arial, sans-serif;
  /* font-size: 14px; */
  font-size: 18px;
  transition: all 0.4s;
}

.link:hover {
  color: black;
  text-decoration: none;
}

.link-active {
  color: black;
  text-decoration: none;
}

.link-inactive {
  color: #D1D1D1;
  text-decoration: none;
}


/* Project General - All */

.content-subtitle.slideshow {
  padding-top: 15px;
  padding-bottom: 30px;
}

.slideshow .carousel {
  margin-left: 75px;
  margin-right: 75px;
  /* margin-left: 100px;
  margin-right: 100px; */
}

.slideshow .carousel-control-prev {
  margin-left: -100px;
}

.slideshow .carousel-control-next {
  margin-right: -100px;
}

.slideshow .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23666666' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.slideshow .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23666666' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.slideshow .carousel-indicators {
  bottom: -50px;
}

.slideshow .carousel-indicators li {
  /* make indicators dots */
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-left: 7px;
  margin-right: 7px;
}

.slideshow .carousel-indicators .active {
  background-color: #666666;
}

.slideshow .carousel-indicators :not(.active):hover {
  /* background-color: #666666; */
  background-color: #000000;
}

.slideshow .carousel-indicators :not(.active) {
  background-color: lightgray;
}


/* Reflection Template */

.reflection .takeaways{
  display: grid;
  grid-template-columns: 3% 93%;
  grid-gap: 40px;
}

.reflection .stage-grey {
  background-color: #F8F8F8;
  width: 100%;
  /* margin-top: 60px; */
  /* padding: 80px 0px 120px 0px; */
  padding: 50px 0px 30px 0px;
  margin-bottom: 60px;
}

.reflection .title {
  font-family: circular-std-bold, Arial, sans-serif;
  line-height: 40px;
}

.reflection .num {
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 45px;
  color: #A2BDBB;
  display: flex;
  align-items: center;
}

.reflection .text {
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
}

.reflection .text.first {
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
  padding-top: 10px;;
}



/* PROJECT PAGE - EQUALICARE */

/* .equalicare .navigation-sub {
  z-index: 10;
} */

.equalicare .cover {
  width: 100%;
  /* height: 540px; */
  /* position: relative; */
}

/* .equalicare .content-title{
  width: 100%;
  background-color: #12B7BB;
  background-color: #6FCAD2;
} */

.equalicare .research .ca-table {
  max-width: 800px;
  margin-top: 20px;
  margin-left: 100px;
  margin-right: 100px;
}

.equalicare .interviews {
  justify-content: center;
  justify-items: center;
  text-align: center;
  padding: 10px 0px;
  font-style: italic;
}

.equalicare .interview-icon {
  display: inline-block;
  max-width: 120px;
  padding: 15px 0px;
}

.equalicare .insights-list .insight-number {
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 30px;
  color: #12B7BB;
  display: flex;
  align-items: center;
}

.equalicare .insights-list {
  display: grid;
  padding: 30px 0px 10px 25px;
  grid-template-columns: 5% 85%;
  /* grid-template-rows: repeat(5, 50px); */
  grid-gap: 30px;
  /* grid-column-gap: 20px; */
}

.equalicare .survey {
  display: grid;
  /* justify-content: center; */
  padding: 30px 0px;
  grid-template-columns: 30% 60%;
  grid-gap: 55px;
  justify-items: center;
}

.equalicare .survey-icon .icon-title  {
  font-family: circular-std-bold, Arial, sans-serif;
  font-size: 20px;
}

.equalicare .survey-img {
  display: flex;
  max-width: 180px;
  margin: auto;
  margin-top: 20px;
}

.equalicare .survey-img.health {
  margin-top: 30px;
  max-width: 200px;
}

/* REMOVED EQUALICARE PERSONA STYLE FROM HERE */

.equalicare .stage-teal {
  background-color: #12B7BB;
  /* background-color: #6FCAD2; */
  width: 100%;
  margin-top: 60px;
  padding: 120px 0px;
}

.equalicare .stage-teal .content-subtitle {
  /* background-color: #12B7BB; */
  width: 1000px;
  color: white;
  padding: 0px;
}

.equalicare .stage-teal .stage-text {
  color: white;
  /* font-weight: 600; */
  padding-top: 20px;
}

.equalicare .problem-framing .stage-grey {
  background-color: #F8F8F8;
  /* background-color: #6FCAD2; */
  width: 100%;
  padding: 80px 0px;
}

.equalicare .problem-framing .stage-grey .content-subtitle {
  /* background-color: #12B7BB; */
  width: 1000px;
  padding: 0px;
}

.equalicare .problem-framing .stage-grey .stage-text {
  padding-top: 20px;
}

.equalicare .problem-framing .design-reqs {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  /* grid-gap: 15px; */
}

.equalicare .problem-framing .design-reqs .icon {
  margin: auto;
  padding: 0px;
  max-height: 65%;
}

.equalicare .problem-framing .design-reqs .icon-title {
  font-weight: 700;
  text-align: center;
}

.equalicare .problem-framing .design-reqs .icon-caption {
  text-align: center;
  font-size: 15px;
  line-height: 22px;
  padding: 10px 10px;
}

.equalicare .ideation .cons-sol .table {
  margin-top: 30px;
  width: 1000px;
  height: 580px;
  /* border: 3px solid #ccc; */
  border: 3px solid #B7E3E5;
  border-radius: 1mm;
  /* background: #eee; */
  background: #ffffff;
  /* margin: auto; */
  /* padding: 15px 10px; */
}

.equalicare .ideation .sel-sol {
  display: grid;
  grid-template-columns: 50% 50%;
  /* background-color: #12B7BB; */
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0px;
  grid-gap: 50px;
}

.equalicare .ideation .sel-sol .icon{
  max-width: 500px;
}

.equalicare .ideation .sel-sol .icon-caption{
  max-width: 500px;
  margin: auto;
  color: black;
  /* font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: 500; */
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
  line-height: 26px;
  margin-left: auto;
  margin-right: auto;
}

.equalicare .stage-teal.solution {
  /* background-color: #12B7BB; */
  background-color: #6FCAD2;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 30px;
  padding: 30px 0px;
}

/* .equalicare .ideation .timeline {
  margin-top: 20px;
  max-width: 850px;
  margin-left: 75px;
  margin-right: 75px;
} */

.equalicare .ideation .lofi-wireframes .carousel-caption {
  position: relative;
  left: auto;
  right: auto;
  color: black;
}

.equalicare .lofi-wireframes.slideshow .carousel-indicators {
  bottom: 0px;
}


.equalicare .design .design-sys {
  display: grid;
  grid-template-columns: 35% 60%;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  grid-gap: 50px;
}

.equalicare .design .design-sys .image {
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.equalicare .design .design-sys .image-text {
  max-width: 100%;
  line-height: 26px;
}

.equalicare .design .design-sys .image-text a {
  text-decoration: none;
}

.equalicare .design .timeline {
  margin-top: 20px;
  max-width: 850px;
  margin-left: 75px;
  margin-right: 75px;
}

.equalicare .design .prototypes {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.equalicare .prototype {
  position: relative;
  width: 100%;
  height: 680px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.equalicare .left .image {
  width: 250px;
  height: 544px;
  margin: 0;
  position: absolute;
  top: 68px;
  left: 0px;
  box-shadow: 0 0 15px #eee;
}

.equalicare .right .image {
  width: 250px;
  height: 544px;
  margin: 0;
  margin-bottom: 50px;
  position: absolute;
  top: 68px;
  left: 70%;
  left: 700px;
  box-shadow: 0 0 15px #eee;
}

.equalicare .left .details {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 450px;
}

.equalicare .right .details {
  width: 55%;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 0px;
  /* width: 250px; */
  /* text-align: right; */
}

.equalicare .prototype .details .title {
  /* font-family: 'Open Sans', 'Arial', sans-serif; */
  /* font-weight: 600; */
  font-family: circular-std-bold, Arial, sans-serif;
  font-size: 26px;
  padding-bottom: 20px;
}

.equalicare .prototype .details .description {
  /* font-family: 'Open Sans', 'Arial', sans-serif; */
  /* font-weight: 400; */
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
  /* line-height: 30px; */
}

.equalicare .prototype .description .pain-points {
  /* color: #12B7BB; */
  color: #00878B;
  font-size: 22px;
}

.equalicare .prototype .description ul {
  padding-left: 20px;
}

.equalicare .reflection .learned {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.equalicare .reflection .learned .image-col {
  padding-left: 0px;
}


.equalicare .reflection .learned .image {
  /* width: 500px;
  height: 375px; */
  width: 500;
  height: 375px;
  padding-bottom: 10px;
  /* width: 600px;
  margin-right: auto;
  margin-left: auto; */
}

.equalicare .reflection .learned .img-caption {
  font-family: circular-std-bold-italic, Arial, sans-serif;
  font-size: 15px;
  text-align: center;
}

.equalicare .reflection .learned .img-thanks {
  font-family: circular-std-book-italic, Arial, sans-serif;
}

.equalicare .reflection .learned .caption {
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
}

.equalicare .next-steps {
  display: grid;
  grid-template-columns: 5% 95%;
  grid-gap: 40px;
}

.equalicare .reflection .stage-grey {
  background-color: #F8F8F8;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 0px;
  padding: 80px 0px 120px 0px;
}

.equalicare .next-steps .step-title {
  font-family: circular-std-bold, Arial, sans-serif;
  line-height: 40px;
}

.equalicare .next-steps .step-num {
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 45px;
  color: #12B7BB;
  display: flex;
  align-items: center;
}

.equalicare .next-steps .step {
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
}

.equalicare .next-steps .step.first {
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
  padding-top: 10px;;
}


/* PROJECT PAGE - PROXXMU */

.proxxmu .research .stage-grey {
  background-color: #F8F8F8;
  /* background-color: #FAFAFA; */
  width: 100%;
  /* margin-top: 40px; */
  padding: 80px 0px 120px 0px;
}

.proxxmu .interviews {
  justify-content: center;
  justify-items: center;
  text-align: center;
  padding: 10px 0px;
  font-style: italic;
}

.proxxmu .research .interview-img {
  width: 80%;
  display: block;
  margin: 40px auto 55px auto;
}

.proxxmu .insights-list .insight-number {
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 30px;
  color: #A2BDBB;
  display: flex;
  align-items: center;
}

.proxxmu .insights-list {
  display: grid;
  padding: 30px 0px 10px 0px;
  grid-template-columns: 5% 85%;
  /* grid-template-rows: repeat(5, 50px); */
  grid-gap: 30px;
  /* grid-column-gap: 20px; */
}

.proxxmu .research .ca-table {
  width: 100%;
}

.proxxmu .content-subheading {
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 20px;
  padding-bottom: 5px;
}

.proxxmu .aff-diag {
  width: 100%;
  padding-bottom: 30px;
}

.proxxmu .research .user-journey {
  width: 100%;
}

.proxxmu .problem-framing .lightbulb {
  /* display: inline; */
  width: 5%;
  margin-right: 20px;
}

.proxxmu .problem-framing .connecting-img {
  width: 70%;
  display: block;
  margin: 0px auto;
}

.proxxmu .problem-framing .problems-table {
  display: grid;
  max-width: 1000px;
  grid-template-columns: 47% 47%;
  grid-column-gap: 50px;
}

/* @media only screen and (max-width: 768px) {
  .proxxmu .problem-framing .problems-table {
    display: grid;
    max-width: 1000px;
    grid-template-columns: 47% 47%;
    grid-column-gap: 50px;
  }
} */

.proxxmu .problem-framing .problems-table .problems-header {
  font-family: circular-std-bold, Arial, sans-serif;
  color: #A2BDBB;
}

.proxxmu .problem-framing .problems-table ul {
  padding-left: 20px;
}

.proxxmu .stage-green {
  /* background-color: #A2BDBB; */
  background-color: #C5DAC1;
  width: 100%;
  margin-top: 60px;
  padding: 100px 0px 120px 0px;
}

.proxxmu .stage-pink .content-subtitle {
  /* background-color: #12B7BB; */
  width: 1000px;
  color: black;
  padding: 0px;
}

.proxxmu .stage-pink .stage-text {
  color: black;
  padding-top: 20px;
}

.proxxmu .problem-framing .stage-grey {
  background-color: #F8F8F8;
  width: 100%;
  padding: 80px 0px 80px 0px;
}

.proxxmu .goals-list {
  display: grid;
  padding: 30px 0px 10px 0px;
  grid-template-columns: 3% 85%;
  grid-gap: 25px;
}

.proxxmu .goals-list .goal-num {
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 30px;
  color: #A2BDBB;
  display: flex;
  align-items: center;
}

.proxxmu .ideation .storyboards img {
  width: 800px;
}

.proxxmu .storyboards.slideshow .carousel {
  margin-left: 150px;
  margin-right: 150px;
}

.proxxmu .ideation .storyboards .carousel-caption {
  position: relative;
  left: auto;
  right: auto;
  color: black;
}

.proxxmu .personas.slideshow .carousel-indicators {
  bottom: -50px;
}

.proxxmu .storyboards.slideshow .carousel-indicators {
  bottom: 0px;
}

.proxxmu .ideation .info-arch {
  width: 75%;
  display: block;
  margin: 0px auto;
}

.proxxmu .ideation .wireframe-system {
  width: 80%;
  display: block;
  margin: 40px auto 0px auto;
}

.proxxmu .ideation .wireframes {
  width: 80%;
  display: block;
  margin: 40px auto 0px auto;
}

/* .proxxmu .design .design-sys {
  width: 55%;
  display: block;
  margin: 0px auto;
} */

.proxxmu .design .design-sys {
  display: grid;
  grid-template-columns: 60% 35%;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  grid-gap: 50px;
}

.proxxmu .design .design-sys .image {
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.proxxmu .design .design-sys .image-text {
  max-width: 100%;
  line-height: 26px;
  margin-top: 50%;
}

.proxxmu .design .prototype {
  width: 100%;
}

.proxxmu .design .interactive-prototype {
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-left: 40%;
}

.proxxmu .reflection .image {
  width: 60%;
  display: block;
  /* margin: 50px auto 20px auto; */
  margin: 0px auto 20px auto;
}

.proxxmu .reflection .img-caption {
  text-align: center;
  margin-bottom: 50px;
  font-family: circular-std-book-italic, Arial, sans-serif;

}

/* when BOTH takeaways & next steps were numbered */
/* .proxxmu .reflection .takeaways, .next-steps {
  display: grid;
  grid-template-columns: 5% 95%;
  grid-gap: 40px;
} */

.proxxmu .reflection .takeaways{
  display: grid;
  grid-template-columns: 3% 93%;
  grid-gap: 40px;
}

.proxxmu .reflection .stage-grey{
  background-color: #F8F8F8;
  width: 100%;
  /* margin-top: 60px; */
  /* padding: 80px 0px 120px 0px; */
  padding: 50px 0px 80px 0px;
  margin-bottom: 60px;
}

.proxxmu .reflection .title {
  font-family: circular-std-bold, Arial, sans-serif;
  line-height: 40px;
}

.proxxmu .reflection .num {
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 45px;
  color: #A2BDBB;
  display: flex;
  align-items: center;
}

.proxxmu .reflection .text {
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
}

.proxxmu .reflection .text.first {
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
  padding-top: 10px;;
}



/* PROJECT PAGE - RECOVERY CAFE */

.recovery .research .stage-red {
  background-color: #BA462C;
  /* background-color: #DFBBA6; */
  width: 100%;
  /* margin-top: 60px; */
  padding: 80px 0px;
}

.recovery .research .stage-red .client {
  display: grid;
  grid-template-columns: 47% 47%;
  grid-gap: 6%;
}

.recovery .research ul {
  padding-left: 20px;
}

.recovery .research .stage-title {
  color: white;
  font-family: circular-std-black, Arial, sans-serif;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  padding-bottom: 20px;
  /* padding-top: 80px; */
}

.recovery .research .stage-red .content{
  color: white;
}

.recovery .research .content-subheading {
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 20px;
  padding-bottom: 5px;
}

/* .recovery .research .grey {
  color: #666666;
} */

.recovery .research .brand-identity {
  width: 70%;
  display: block;
  margin: 0px auto 50px auto;
}

.recovery .research .website {
  width: 90%;
  display: block;
  margin: 20px auto 60px auto;
}

.recovery .research .stage-grey {
  background-color: #F8F8F8;
  width: 100%;
  padding: 40px 0px 60px 0px;
}

.recovery .insights-list .insight-number {
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 30px;
  color: #BA462C;
  display: flex;
  align-items: center;
}

.recovery .insights-list {
  display: grid;
  padding: 30px 0px 10px 25px;
  grid-template-columns: 5% 85%;
  /* grid-template-rows: repeat(5, 50px); */
  grid-gap: 30px;
  /* grid-column-gap: 20px; */
}

.recovery .identity .logo-ideation {
  width: 60%;
  display: block;
  margin: 0px auto 50px auto;
}

.recovery .identity .logo-iterations {
  width: 75%;
  display: block;
  margin: 30px auto 50px auto;
}

.recovery .identity .logo {
  width: 50%;
  display: block;
  /* margin: 30px auto 0px auto; */
  margin-left: 20%;
}

.recovery .identity .icon-ideation {
  width: 80%;
  display: block;
  margin: 0px auto 25px auto;
}

.recovery .identity .iconography {
  /* width: 80%; */
  width: 95%;
  display: block;
  margin: 50px auto;
}

.recovery .identity .color-palette {
  width: 80%;
  display: block;
  margin: 50px auto;
}

/* .recovery .identity .typography {
  width: 70%;
  display: block;
  margin: 50px auto;
} */

.recovery .identity .typography {
  display: grid;
  grid-template-columns: 40% 50%;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  grid-gap: 60px;
}

.recovery .identity .typography .image {
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.recovery .identity .typography .image-text {
  max-width: 100%;
  line-height: 26px;
}

.recovery .design .info-arch {
  width: 85%;
  display: block;
  margin: 0px auto;
}

.recovery .design .prototype-link {
  text-decoration: none;
  color: #BA463C;
  /* color: grey; */
}

.recovery .design .prototype-link:hover {
  text-decoration: underline;
  color: #BA463C;
}

.recovery .design .interactive-prototype {
  display: block;
  margin-left: 30%;
  /* margin: 0px auto; */
}

.recovery .book .content-title.remove-padding {
  padding-top: 0px;
  margin-top: 0px;
}

.recovery .brandbook.slideshow .carousel {
  margin-left: 150px;
  margin-right: 150px;
}

.recovery .reflection .num {
  font-family: circular-std-medium, Arial, sans-serif;
  font-size: 45px;
  color: #BA463C;
  display: flex;
  align-items: center;
}

.recovery .reflection .stage-grey {
  /* background-color: #F8F8F8;
  width: 100%; */
  padding: 30px 0px 0px 0px;
  margin-bottom: 20px;
}

.recovery .reflection .next-steps {
  margin-bottom: 70px;
}



/* EXTRA WORKS */
.extra-works .extra-page-title {
  font-family: circular-std-bold, Arial, sans-serif;
  font-size: 40px;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  padding-top: 30px;
  text-align: center;
  position: relative;
}

.extra-works .extra-page-subtitle {
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 20px;
  /* padding-bottom: 50px; */
  text-align: center;
}

.extra-works .extra-title {
  font-family: circular-std-bold, Arial, sans-serif;
  font-size: 32px;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 50px; */
  padding-top: 100px;
  padding-bottom: 20px;
  text-align: left;
  position: relative;
}

.extra-works .extra-text {
  /* font-family: circular-std-book-italic, Arial, sans-serif; */
  font-family: circular-std-book, Arial, sans-serif;
  font-size: 18px;
  line-height: 26px;
}

.extra-works .medpad .extra-title {
  color: #7D48F6;
}

.extra-works .medpad .prompt {
  font-family: circular-std-book-italic, Arial, sans-serif;
}

.extra-works .airquality .extra-title {
  color: #88B7E2;
}

.extra-works .airquality .air-video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
  height: 0;
  margin: 20px 0px;
}
.extra-works .airquality .air-video-container iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

.extra-works .amazonfresh .extra-title {
  color: #89C440;
}

.extra-works .amazonfresh .fresh-pres {
  margin-top: 20px;
  margin-bottom: 20px;
}









